<template>
  <div>
    <!-- Main Row -->
    <div class="row">
      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Detail Pasien'">
          <template v-slot:body>
            <div class="row align-items-center" v-if="dataLoaded == true">
              <!-- Profile Image -->
              <div class="col-md-4">
                <div
                  class="d-flex justify-content-center align-items-center image-container"
                >
                  <div style="height: 350px; width: 350px; position: relative">
                    <img
                      class="image"
                      v-if="data != null"
                      :src="data.photo"
                      @load="onImageLoad()"
                    />
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div class="col">
                <table class="table mb-3">
                  <tr>
                    <td><strong>ID Pasien</strong></td>
                    <td>{{ data.patient_id }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nama</strong></td>
                    <td>{{ data.patient_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>No. KTP</strong></td>
                    <td>{{ data.id_card_number }}</td>
                  </tr>
                  <tr>
                    <td><strong>Email</strong></td>
                    <td>{{ data.email }}</td>
                  </tr>
                  <tr>
                    <td><strong>Tempat Tanggal Lahir</strong></td>
                    <td>{{ data.birt_place }} ,{{ data.birt_date }}</td>
                  </tr>
                  <tr>
                    <td><strong>Umur</strong></td>
                    <td>{{ data.age + " Tahun" }}</td>
                  </tr>
                  <tr>
                    <td><strong>Jenis Kelamin</strong></td>
                    <td>{{ data.gender }}</td>
                  </tr>
                  <tr>
                    <td><strong>Status</strong></td>
                    <td>{{ data.status }}</td>
                  </tr>
                  <tr>
                    <td><strong>Golongan Darah</strong></td>
                    <td>{{ data.blood_type }}</td>
                  </tr>
                  <tr>
                    <td><strong>Alergi</strong></td>
                    <td>{{ data.allergy }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nama Orang Tua</strong></td>
                    <td>{{ data.parent_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor Telepon</strong></td>
                    <td>{{ data.phone }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor Telepon Seluler</strong></td>
                    <td>{{ data.mobile_phone }}</td>
                  </tr>
                  <tr>
                    <td><strong>Alamat</strong></td>
                    <td v-if="data.country_id == 74">
                      {{
                        data.address +
                        ", " +
                        data.capital_name +
                        ", " +
                        data.province_name 
                      }}
                    </td>
                    <td v-if="data.country_id != 74">
                      {{ data.address + ", " + data.country_name }}
                    </td>
                  </tr>
                  <!-- <tr>
                    <td><strong>Tempat Tinggal</strong></td>
                    <td>{{ data.capital_name +", "+ data.province_name}}</td>
                  </tr> -->
                  <tr>
                    <td><strong>Dokter Yang Menangani</strong></td>
                    <td
                      v-if="
                        data.doctor_in_charge.length != 0 &&
                        listData.length != 0
                      "
                    >
                      <div
                        v-for="index in data.doctor_in_charge.length"
                        :key="index"
                      >
                        <li>
                          {{ listData[index - 1].name }}
                        </li>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Dokter Penaggung Jawab (DPJP)</strong></td>
                    <td
                      v-if="
                        data.responsible_doctor.length != 0 && dpjb.length != 0
                      "
                    >
                      <div
                        v-for="index in data.responsible_doctor.length"
                        :key="index"
                      >
                        <li>
                          {{
                            dpjb[index - 1] != null ? dpjb[index - 1].name : ""
                          }}
                        </li>
                      </div>
                    </td>
                  </tr>
                </table>
                <button
                  @click="$router.push({ path: '/patient/edit/' + data.id })"
                  class="btn mx-1 btn-success"
                  v-if="manipulateBtn == true"
                >
                  <i class="far fa-fw fa-edit"></i> Edit
                </button>
                <button
                  @click="deleteData(data.id)"
                  class="btn mx-1 btn-danger"
                  v-if="manipulateBtn == true"
                >
                  <i class="far fa-fw fa-trash-alt"></i> Hapus
                </button>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <div class="col-md-12">
        <Card :title="'Diagnosa Pasien'">
          <template v-slot:body>
            <div class="row">
              <div class="col-md-12">
                <b-button
                  size="sm"
                  variant="primary"
                  class="mr-1"
                  @click="$bvModal.show('modal-patient-x-diagnose')"
                >
                  <i class="fas fa-plus px-0"></i> Diagnosa
                </b-button>
              </div>
            </div>
            <b-table
              :items="patientXDiagnose"
              :fields="fieldsPatientXDiagnose"
              responsive="sm"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{
                    width: field.key === 'actions' ? '20%' : '',
                    width: field.key === 'title' ? '30%' : '',
                  }"
                />
              </template>

              <template #cell(action)="data">
                <b-button
                  size="sm"
                  class="mr-1 btn-danger"
                  @click="deletePatientXDiagnosa(data.item.id)"
                  v-b-tooltip.hover
                  title="Hapus"
                  placement="bottom"
                >
                  <i class="fas fa-trash px-0"></i>
                </b-button>
              </template>
            </b-table>
          </template>
        </Card>
      </div>
    </div>

    <ModalPatientXDiagnose
      :patient_id="data.id"
      page="add"
      @updateFormDiagnoseChoosenList="getPatientXDiagnose"
    />
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
import ModalPatientXDiagnose from "@/component/masters/diagnoses/Modal.vue";

export default {
  data() {
    return {
      // data
      data: null,
      birt_date: null,
      listData: [], //responsible doctor
      dpjb: [],

      // Other
      show: true,
      imgLoaded: false,
      dataLoaded: false,
      // access management
      manipulateBtn: false,

      detailPatientXDiagnose: [],
      patientXDiagnose: [],
      fieldsPatientXDiagnose: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "diagnose_name",
          label: "Diagnosa",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
        },
      ],
    };
  },

  components: {
    Card,
    ModalPatientXDiagnose,
  },

  methods: {
    onImageLoad() {
      this.imgLoaded = true;
    },

    async get() {
      this.data = await module.get("patients/" + this.$route.params.id);
      this.data["age"] = moment().diff(this.data.birt_date, "years");
      this.data["birt_date"] = moment(this.data.birt_date).format("DD-MM-YYYY");
      console.log(this.data);
      if (this.data == null) {
        this.$router.push("/patient/list");
      } else {
        await this.getDetailPatient();
        await this.getPatientXDiagnose(this.data.id);
        this.$root.$emit(
          "updateModalPatientXDiagnoseOnSetPatient",
          this.data.id
        );
        this.dataLoaded = true;
      }
    },

    async getPatientXDiagnose(patient_id) {
      let route = "patient-x-diagnoses/all";
      let response = await module.paginate(route, `?patient_id=${patient_id}`);
      if (this.page != "detail") {
        this.patientXDiagnose = response.data;
        console.log(response.data, "DTATTTT", patient_id);
      } else {
        this.detailPatientXDiagnose = response.data;
      }
    },

    async deletePatientXDiagnosa(id) {
      let result = await module.delete("patient-x-diagnoses/" + id);
      console.log(result, "resss");
      if (result) {
        this.getPatientXDiagnose(this.data.id);

        this.$root.$emit("updateModalPatientXDiagnoseFromPage", this.data.id);
      }
    },

    async deleteData(id) {
      let result = await module.delete("patients/" + id);
      if (result) {
        this.$router.push("/patient/list");
      }
    },

    async getDetailPatient() {
      let b, c;
      this.listData = [];
      this.dpjb = [];
      for (b = 0; b <= this.data.doctor_in_charge.length - 1; b++) {
        let response = await module.get(
          "doctors/" + this.data.doctor_in_charge[b]
        );
        this.listData.push(response);
      }
      for (c = 0; c <= this.data.responsible_doctor.length - 1; c++) {
        let res = await module.get(
          "doctors/" + this.data.responsible_doctor[c]
        );
        this.dpjb.push(res);
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        console.log("looping");

        if (access_right[a] == "3002") {
          this.manipulateBtn = true;
        }
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pasien", route: "" },
      { title: "Daftar Pasien", route: "/patient/list" },
      { title: "Detail" },
    ]);
    // get
    this.get();
    this.setActiveMenu();
  },
};
</script>